const header = {
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function bindUI() {
		this.ui.$window = $(window);
		this.ui.$document = $(document);
		this.ui.$body = $("body");

		this.ui.$header = $(".js-header");

		//old class doesn't exist 
		// this.ui.$menuToggle = $(".js-toggle-menu");
		this.ui.$menuToggle = $(".js-navToggle");
		//same old class doesn't exist
		// this.ui.$menuContainer = $(".js-menu-container");
		this.ui.$menuContainer = $(".js-navWrap")
		//same old class doesn't exist
		// this.ui.$menuNav = $(".js-menu-nav");
		this.ui.$menuNav = $(".js-nav-itemsToWrap");

	},

	bindEvents: function bindEvents() {
		this.ui.$window.on('scroll', this.menuOnScroll.bind(this));
		
		this.ui.$menuToggle.on('click', this.menuMobile.bind(this));
	},

	menuOnScroll: function(e) {
		let self = this;
		let $this = $(e.target);
		
		if($this.scrollTop() > 50 && !this.ui.$header.hasClass("header--smaller")) {
			$(".js-header").addClass("header--smaller");
		}
		if($this.scrollTop() === 0 && this.ui.$header.hasClass("header--smaller")) {
			$(".js-header").removeClass("header--smaller");
		}
	},

    menuMobile: function(e) {
		let $this = this.ui.$menuToggle;
		
		if($this.hasClass("opened")){
			// this.ui.$body.removeClass("disable-scroll");
			this.ui.$body.removeClass("disableScroll");
			$this.removeClass("opened");
			this.ui.$menuContainer.removeClass("opened");
			this.ui.$menuNav.removeClass("opened");
		} else {
			this.ui.$body.addClass("disable-scroll");
			$this.addClass("opened");
			this.ui.$menuContainer.addClass("opened");
			this.ui.$menuNav.addClass("opened");
		}
    }

};

module.exports = header;