const nav = {
	
	ui: {},

	init: function() {
		this.bindUI();
		this.bindSelect2();
		this.bindEvents();
	},

	bindUI: function() {
		this.ui.$selectMonth = $('.js-dashboard-selectMonth');
		this.ui.$selectTitle = $('.js-dashboard-selectTitle');
	},

	bindSelect2: function() {
		let select2_options = {
			placeholder: "-",
			width: '\'style\'',
			theme: 'default select2BigSelect',
			//containerCssClass: "select2BigSelect",
			dropdownCssClass: "select2BigSelect__dropdown",
			selectionCssClass: "select2BigSelect__selection"
		};
		this.ui.$selectMonth.select2(select2_options);
		this.ui.$selectTitle.select2(select2_options);
	},
	
	bindEvents: function() {
		this.ui.$selectMonth.on('change', this.loadPage.bind(this));
		this.ui.$selectTitle.on('change', this.loadPage.bind(this));
	},

	loadPage: function(e) {
		let $this = $(e.currentTarget);
		window.location = $this.val();
	},

	print: function() {
		window.print();
	},
};

module.exports = nav;