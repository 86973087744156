// Import jQuery => utilise celui de wp de base, evite duplicat de jquery
// import jQuery from "jquery";
// export for others scripts to use
window.$ = window.jQuery = jQuery;

// Mute Jquery Migrate console warning...
jQuery.migrateMute = true

// Require modules
import browsetClassHelpers from './modules/browser-class-helpers.js'
import header from './modules/header.js'
import sortableTable from './modules/sortableTable'
import salePoints from './modules/salePoints'
import nav from './modules/nav'
//import materialForm from './modules/material-form.js'
//import navScrollHighlight from './modules/nav-scroll-highlight.js'
import popin from './modules/popin.js'
//import scrollAnchor from './modules/scroll-anchor.js'
//import ssTooltip from './modules/tooltip'
//import shareLinks from './modules/shareLinks'

// import checkVisible from './modules/checkVisible'

// Import Slick
//import 'slick-carousel'

// Datatables
import absolute from 'datatables.net-plugins/sorting/absolute';
import dt from 'datatables.net-dt';
dt( window, $, absolute );
absolute( window, $ );

// We do this to make it available for the google map callback from the .js include
window.salePoints = salePoints;

$(function(){
	$("html").removeClass("no-js");

	browsetClassHelpers.init();
	
	header.init();

	if ($(".js-sortableTable").length) {
		sortableTable.init();
	}

	if ($(".js-googleMap-salePoints").length) {
		salePoints.init();
	}

	if ($(".js-dashboard-selectMonth").length) {
		nav.init();
	}

	if ($(".js-popin").length) {
		popin.init();
	}
});
