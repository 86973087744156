import { MarkerClusterer } from "@googlemaps/markerclusterer"

const salePoints = {
	
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();

		this.bindDataTable();
	},

	bindUI: function() {
		this.ui.$googleMap_salePoints = $('.js-googleMap-salePoints');
	},

	bindEvents: function() {

	},

	bindDataTable: function() {
		$('.js-datatable').DataTable({
			language: {
				url: '//cdn.datatables.net/plug-ins/1.11.5/i18n/fr-FR.json'
			},
			columnDefs: [{
				type: $.fn.dataTable.absoluteOrder( [
					{ value: '', position: 'bottom' }
				] ),
				targets: '_all'
			}]
		});
	},

	initGoogleMap: function (e) {
		let bounds = new google.maps.LatLngBounds();

		const map = new google.maps.Map(document.getElementById("googleMap__salePoints"), {
			center: { lat: 46.912, lng: 2.439 },
			zoom: 6,
			disableDefaultUI: true, // a way to quickly hide all controls
			//mapTypeControl: false,
			scaleControl: true,
			zoomControl: true,
			mapTypeId: 'roadmap'
		});

		const infoWindow = new google.maps.InfoWindow({
			content: "",
			disableAutoPan: true,
		});

		// Add some markers to the map.
		const markers = window.salePointsDatas.map((salePointData, i) => {
			const marker = new google.maps.Marker({
				position: {lat: salePointData.lat, lng: salePointData.lng},
				label: '',
				title: salePointData.title,
				icon: {
					url: wordpress_vars.template_dir + '/resources/public/images/google-map/marker-blue-light.png',
					size: new google.maps.Size(27, 34),
					origin: new google.maps.Point(0, 0)
				},
			});

			bounds.extend(marker.position);

			let content = '';
			content += '<div class="googleMap__infoWindow">';
			content += "Type d'établissement : " + salePointData.typology + '<br>';
			content += "Enseigne : " + salePointData.shop_name + '<br>';
			content += "Nom : " + salePointData.name + '<br>';
			content += "Adresse : " + salePointData.address + '<br>';
			content += "Code Postal : " + salePointData.postal_code + '<br>';
			content += "Commune : " + salePointData.city + '<br>';
			content += "Taux d'invendus moyen mensuel : " + salePointData.unsold_rate + '<br>';
			content += '</div>';


			// markers can only be keyboard focusable when they have click listeners
			// open info window when marker is clicked
			marker.addListener("click", () => {
				infoWindow.setContent(content);
				infoWindow.open(map, marker);
			});
			return marker;
		});

		const renderer = {
			render({count, position}) {
				let clusterIcon;
				if (count >= 15) {
					clusterIcon = wordpress_vars.template_dir + '/resources/public/images/google-map/markerclusterer/n2.png';
				} else {
					clusterIcon = wordpress_vars.template_dir + '/resources/public/images/google-map/markerclusterer/n1.png';
				}
				return new google.maps.Marker({
					label: {text: String(count), color: "white", fontSize: "12px"},
					position,
					icon: clusterIcon,
					// adjust zIndex to be above other markers
					zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
				})
			}
		}
		// Add a marker clusterer to manage the markers.
		const cluster = new MarkerClusterer({ markers, map, renderer });

		map.fitBounds(bounds);
	},

};

module.exports = salePoints;